
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import InformationDDC from "../DDCReports/InformationDDC.vue";
import { formatFinancialAmounts } from '../../utils/format'

export default Vue.extend({
  name: "HIVAndHCVInfection",
  components: { InformationDDC },
  data: () => ({
    data: [],
    isLoading: false,
    payload: {
      year: null,
    },
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["agsreport/getYearSelected"].year;
    },
    title() {
      return i18n.t("reports.agsreports.HivHcvInfection.title");
    },
    headers() {
      return [
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.country"),
          value: "country",
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.surveyYear"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.hem_HIV_Living"),
          value: "hem_HIV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.vwD_HIV_Living"),
          value: "vwD_HIV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.obD_HIV_Living"),
          value: "obD_HIV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.hem_HIV_New"),
          value: "hem_HIV_New",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.vwD_HIV_New"),
          value: "vwD_HIV_New",
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.obD_HIV_New"),
          value: "obD_HIV_New",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.hem_HCV_Living"),
          value: "hem_HCV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.vwD_HCV_Living"),
          value: "vwD_HCV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.obD_HCV_Living"),
          value: "obD_HCV_Living",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.hem_HCV_Active"),
          value: "hem_HCV_Active",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.vwD_HCV_Active"),
          value: "vwD_HCV_Active",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.obD_HCV_Active"),
          value: "obD_HCV_Active",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.hem_HCV_New"),
          value: "hem_HCV_New",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.vwD_HCV_New"),
          value: "vwD_HCV_New",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("reports.agsreports.HivHcvInfection.obD_HCV_New"),
          value: "obD_HCV_New",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
      ];
    },
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.payload.year = this.yearSelected;
      axios
        .get("report/DDC/HIV-HCV-Infection", {
          params: { year: this.yearSelected },
        })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(`report/ddc/HIV-HCV-Infection/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.agsreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.yearSelected) {
      this.getData();
    }
  },
  watch: {
    yearSelected() {
      this.getData();
    },
  },
});
